import React from 'react';
import MyCalendar from '../../components/calendar/Calendar';

const Calendar = () => {
  return (
    <div className='h-full'>
      <MyCalendar/>
    </div>
  )
}

export default Calendar