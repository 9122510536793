import React, { useState, useEffect } from 'react';
import ItemCard from '../../../components/ItemCard';
import { baseUrl } from '../../../utils/constant';
import axios from 'axios';

const Pharmacy = () => {
    const [items, setItems] = useState([]);
    const [cart, setCart] = useState({}); // State to track added items with their quantities

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${baseUrl}/inventory.php`);
                console.log(res.data);
                setItems(res.data.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchData();
    }, []);

    // Handle Add: Increase item quantity in the cart
    const handleAdd = (item) => {
        setCart(prevCart => ({
            ...prevCart,
            [item.id]: prevCart[item.id] ? prevCart[item.id] + 1 : 1,
        }));
    };

    // Handle Minus: Decrease item quantity in the cart or remove item if quantity is 0
    const handleMinus = (item) => {
        setCart(prevCart => {
            const newQuantity = prevCart[item.id] ? prevCart[item.id] - 1 : 0;
            if (newQuantity <= 0) {
                const { [item.id]: _, ...rest } = prevCart; // Remove item from cart if quantity is 0
                return rest;
            }
            return {
                ...prevCart,
                [item.id]: newQuantity,
            };
        });
    };

    // Calculate total items in cart for summary
    const cartItems = Object.entries(cart).map(([id, quantity]) => {
        const item = items.find(item => item.id === parseInt(id));
        return { ...item, quantity };
    });

    // Handle form submission (saving cart)
    const handleSave = (e) => {
        e.preventDefault();
        // Implement saving functionality, e.g., sending cart data to an API endpoint
        console.log('Cart saved:', cartItems);
    };

    return (
        <div className='w-full h-full flex'>
            <div className='w-3/4 border-r-2 border-slate-500'>
                <div className='grid grid-cols-4 gap-4'>
                    {
                        items?.map((item, i) => (
                            <ItemCard
                                key={i}
                                data={item}
                                handleAdd={() => handleAdd(item)}
                                handleMinus={() => handleMinus(item)}
                            />
                        ))
                    }
                </div>
            </div>

            {/* SUMMARY */}

            <div className='w-1/4 pl-4'>
                <h2 className='font-bold text-lg mb-4'>SUMMARY</h2>
                <form onSubmit={handleSave}>
                    <div className="w-full mb-4">
                        <input
                            type="text"
                            name='ref'
                            placeholder="Reference"
                            className="border border-appColor p-2 w-full rounded-lg"
                        />
                    </div>
                    <div className='mb-4 flex flex-col gap-2'>
                        {/* Display items in the cart */}
                        {cartItems.length > 0 ? (
                            cartItems.map((item, i) => (
                                <div key={i} className="flex justify-between items-center rounded-md bg-slate-300 shadow-md p-1 px-2">
                                    <div className='w-full flex flex-col'>
                                        <span className="truncate font-bold capitalize w-3/4">{item.product}</span>
                                        <span>{item.quantity} {item.unit}</span>
                                    </div>
                                    <span className='text-base'>₦{item.price * item.quantity}</span>
                                </div>

                            ))
                        ) : (
                            <p>No items in cart</p>
                        )}
                    </div>

                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Save
                    </button>
                </form>
            </div>
        </div>
    );
}

export default Pharmacy;
