import React, { useState } from 'react';
import axios from 'axios';
import { MdPayments } from 'react-icons/md';
import PaystackPop from '@paystack/inline-js';
import { baseUrl } from '../../../utils/constant';
import { useSelector } from 'react-redux';
import Alert from '../../../components/Alert';

const AddPayment = ({ closeAddModal }) => {
    const { data } = useSelector((state) => state.user);
    const username = data?.firstname + " " + data?.lastname;

    console.log(username);

    const [formData, setFormData] = useState({
        email: '',
        amount: '',
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const save = async () => {
        setLoading(true);
        setError(null);
    
        try {
            const data = {
                email: formData.email,
                amount: formData.amount * 100, // Convert amount to kobo
            };
    
            const headers = {
                Authorization: `Bearer sk_test_9065a1161a824bba3b079e060a6b8a58ec2381ad`,
                'Content-Type': 'application/json'
            };
    
            const res = await axios.post('https://api.paystack.co/transaction/initialize', data, { headers });
            if (res.status === 200) {
                const { authorization_url, access_code } = res.data.data;
    
                // Initialize the Paystack transaction popup
                const paystack = new PaystackPop();
                paystack.newTransaction({
                    key: 'pk_test_79a1bfee534cfd1239465581c33956d3b9a80029', // Replace with your Paystack public key
                    email: formData.email,
                    amount: formData.amount * 100, // Amount in kobo
                    reference: access_code,
                    onSuccess: async (transaction) => {
                        console.log('Payment Success', transaction);
    
                        const result = {
                            email: formData?.email,
                            amount: formData?.amount,
                            user: username, // Ensure 'username' is defined or passed correctly
                            ref: transaction?.reference,
                            status: transaction?.status,
                        };
    
                        const ress = await axios.post(`${baseUrl}/transaction.php`, result);
                        if (ress.status === 200) {
                            Alert(ress.data.status, ress.data.message);
                        } else {
                            Alert(ress.data.status, ress.data.message);
                        }
                    },
                    onCancel: async () => {
                        try {
                            const result = {
                                email: formData?.email,
                                amount: formData?.amount,
                                user: username, // Ensure 'username' is defined or passed correctly
                                status: "Canceled",
                            };
    
                            const ress = await axios.post(`${baseUrl}/transaction.php`, result);
                            if (ress.status === 200) {
                                Alert("warning", "PAYMENT CANCELED");
                            } else {
                                Alert("error", ress.data.message || "Failed to log cancellation");
                            }
                        } catch (error) {
                            console.error('Error while logging payment cancellation:', error);
                            Alert("error", "An error occurred while logging the cancellation.");
                        }
                        console.log('Payment Canceled');
                    }
                });
            } else {
                setError('Failed to initialize payment.');
            }
        } catch (error) {
            setError('An error occurred while initializing payment.');
            console.error('An error occurred:', error);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <div>
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-appColor bg-opacity-15 sm:mx-0 sm:h-10 sm:w-10">
                        <MdPayments className='text-appColor text-xl' />
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg mt-2 leading-6 font-extrabold uppercase text-appColor" id="modal-title">New Payment</h3>
                        <form className="flex flex-col gap-4 mt-3">
                            <div className="grid grid-cols- md:grid-cols-2 gap-4 mt-3">
                                <div className="w-full">
                                    <input
                                        type="email"
                                        name="email"
                                        id="email"
                                        className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                                        placeholder="Enter Customer Email"
                                        value={formData.email}
                                        onChange={handleChange}
                                    />
                                </div>
                                <div className="w-full">
                                    <input
                                        type="number"
                                        name="amount"
                                        id="amount"
                                        className="border-2 focus:border-blue-500 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                                        placeholder="Enter Amount"
                                        value={formData.amount}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                    type="button"
                    onClick={save}
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 border-appColor text-base font-medium text-appColor hover:bg-appColor hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                    disabled={loading}
                >
                    {loading ? 'Processing...' : 'Make Payment'}
                </button>
                <button
                    type="button"
                    onClick={closeAddModal}
                    className="mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                >
                    Cancel
                </button>
            </div>
            {error && <div className="text-red-500">{error}</div>}
        </div>
    );
}

export default AddPayment;
