import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { baseUrl } from '../../../utils/constant';
import axios from 'axios';
import { MdOutlineAdd } from 'react-icons/md';
import Alert from '../../../components/Alert';

const Inventory = () => {
    const [filter, setFilter] = useState("");
    const [inventory, setInventory] = useState([]);

    const [formData, setFormData] = useState({
        product: '',
        price: '',
        category: '',
        quantity: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClear = () => {
        // Clear the formData state
        setFormData({
            product: '',
            price: '',
            category: '',
            quantity: '',
        });
    }

    const save = async () => {
        setLoading(true);
        try {
            console.log(formData);
            // Save the category
            const res = await axios.post(`${baseUrl}/inventory.php`, formData);
            console.log(res);
            if (res.status === 201) {
                Alert(res.data.status, res.data.message);
            } else {
                Alert(res.data.status, res.data.message);
                setError(res.data.message);
            }
        } catch (error) {
            Alert('error', 'An error occurred while saving the category');
            console.error('An error occurred while saving the category', error.message);
        }
        finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await axios.get(`${baseUrl}/drug_category.php`);
                setInventory(res.data.data);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        };

        fetchData();
    }, [inventory]);


    return (
        <div className='h-full p-5 flex '>

            <div className='h-full w-3/4 border-r-2 border-slate-400 pr-8'>
                <div className='grid grid-cols-4 gap-5'>
                    {inventory?.map((item) => (
                        <Link to={`/dashboard/inventory/${item.category}`}>
                            <div className=' bg-white p-3 rounded-md cursor-pointer font-extrabold text-slate-400 hover:text-appColor' key={item.id}>{item.category}</div>
                        </Link>
                    ))}
                </div>
            </div>
            <div className='h-full w-1/4 pl-8 flex flex-col items-start'>
                <div className='flex gap-4'>
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdOutlineAdd className='text-green-500 text-base' />
                    </div>
                    <h3 className="text-lg mt-2 leading-6 font-bold uppercase text-slate-800" id="modal-title">Add Product</h3>
                </div>
                <div className="w-full mt-3 flex flex-col gap-5">
                    <form className="flex flex-col gap-4 mt-3 w-full">
                        <div className="w-full">
                            <input
                                type="text"
                                name="product"
                                id="product"
                                className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                                placeholder="PRODUCT"
                                onChange={handleChange}
                            />
                        </div>
                        <div className="w-full">
                            <label htmlFor="gender" className="sr-only">gender</label>
                            <select
                                name="category"
                                id="category"
                                className="border-2 focus:border-blue-500 block w-full sm:text-sm p-2 text-gray-400 border-slate-300 rounded-md"
                                onChange={handleChange}
                            >
                                <option value="">CATEGORY</option>
                                {inventory.map((item, i) => (<option key={i} value={item.category}>{item.category}</option>))}
                            </select>
                        </div>
                        <div className='flex gap-4'>
                            <div className="w-full">
                                <input
                                    type="text"
                                    name="price"
                                    id="price"
                                    className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                                    placeholder="PRICE"
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="w-full">
                                <input
                                    type="number"
                                    name="quantity"
                                    id="quantity"
                                    className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                                    placeholder="QUANTITY"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </form>

                    <div className="w-full flex flex-row-reverse items-center justify-start gap-4">
                        <button
                            type="button"
                            onClick={save}
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 border-green-300 text-base font-medium text-green-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                            Save
                        </button>
                        <button
                            type="button"
                            onClick={handleClear}
                            className="mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                        >
                            Clear
                        </button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default Inventory;
