export const baseUrl = "https://bethesdamercymemorial.com/server/api";
// export const baseUrl = "http://localhost/mercy/api"; 

export const role = [
    "ADMIN",
    "ICT",
    "PATIENT",
    "NURSE",
    "DOCTOR",
    "ACCOUNTANT",
    "RECEPTIONIST",
    "PHARMACIST",
    "LABORATORY SCIENTIST",
]

export const status = [
    "IN-PATIENT",
    "OUT-PATIENT",
]

export const gender =  [
    "MALE",
    "FEMALE",
]

export const religion = [
    "CHRISTIAN",
    "HINDU",
    "ISLAM",
]

export const marital_status = [
    "SINGLE",
    "MARRIED" 
]

export const occupations = [
    "Accountant",
    "Actor",
    "Actress",
    "Administrator",
    "Agricultural Engineer",
    "Air Traffic Controller",
    "Architect",
    "Artist",
    "Athlete",
    "Banker",
    "Biologist",
    "Builder",
    "Business Analyst",
    "Business Owner",
    "Carpenter",
    "Chef",
    "Chemist",
    "Civil Engineer",
    "Cleaner",
    "Coach",
    "Consultant",
    "Customer Service Representative",
    "Data Analyst",
    "Dentist",
    "Designer",
    "Developer",
    "Dietitian",
    "Doctor",
    "Economist",
    "Electrician",
    "Engineer",
    "Entrepreneur",
    "Farmer",
    "Fashion Designer",
    "Financial Advisor",
    "Firefighter",
    "Fitness Trainer",
    "Florist",
    "Graphic Designer",
    "Hairdresser",
    "Journalist",
    "Judge",
    "Lawyer",
    "Librarian",
    "Logistics Manager",
    "Machine Operator",
    "Marketing Specialist",
    "Mathematician",
    "Mechanic",
    "Medical Assistant",
    "Med Lab",      
    "Military Personnel",
    "Musician",
    "Nurse",
    "Nutritionist",
    "Painter",  
    "Paramedic",
    "Pharmacist",
    "Photographer",
    "Physical Therapist",
    "Pilot",
    "Plumber",
    "Police Officer",
    "Politician",
    "Professor",
    "Project Manager",
    "Psychologist",
    "Real Estate Agent",
    "Receptionist",
    "Researcher",
    "Retail Worker",
    "Salesperson",
    "Scientist",
    "Secretary",
    "Social Worker",
    "Software Developer",
    "Surgeon",
    "Teacher",
    "Technician",
    "Translator",
    "Travel Agent",
    "Veterinarian",
    "Waiter",
    "Waitress",
    "Web Developer",
    "Writer"
];

