import React, { useState } from 'react';
import { haem, chem, sens } from './fields';

const Investigation = () => {
    // State to manage form inputs
    const [formData, setFormData] = useState({
        patientName: '',
        patientPhone: '',
        patientRef: '',
        sex: '',
        age: '',
        date: '',
        haemResults: {},
        chemResults: {},
        sensResults: {}
    });

    // Handle changes for all inputs
    const handleInputChange = (e, type, index) => {
        const { name, value, checked, type: inputType } = e.target;

        if (type === 'haem' || type === 'chem' || type === 'sens') {
            setFormData(prevState => ({
                ...prevState,
                [`${type}Results`]: {
                    ...prevState[`${type}Results`],
                    [index]: inputType === 'checkbox' ? checked : value
                }
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    // Handle form submission
    const handleSubmit = () => {
        console.log(formData);
        // You can replace the console.log with your API call or other logic
    };

    return (
        <div className='p-5 flex flex-col items-center gap-5'>
            <header className='w-full flex justify-center items-center'>
                <div className='text-center'>
                    <h2 className='font-extrabold text-appColor text-5xl'>MERCY MEMORIAL HOSPITAL</h2>
                    <p className='font-extrabold text-xl'>LAB REQUEST FORM</p>
                </div>
            </header>
            <main className='w-[90%] p-3 flex flex-col gap-5 justify-center items-center'>
                <section className='w-full grid grid-cols-3 gap-4'>
                    <label className='w-full flex flex-col gap-2'>
                        <p className='font-extrabold uppercase text-appColor'>Patient's fullname</p>
                        <input
                            type="text"
                            name="patientName"
                            value={formData.patientName}
                            onChange={handleInputChange}
                            className='w-full p-2 rounded-md'
                        />
                    </label>
                    <label className='w-full flex flex-col gap-2'>
                        <p className='font-extrabold uppercase text-appColor'>Patient's ref</p>
                        <input
                            type="text"
                            name="patientRef"
                            value={formData.patientRef}
                            onChange={handleInputChange}
                            className='w-full p-2 rounded-md'
                        />
                    </label>
                    <label className='w-full flex flex-col gap-2'>
                        <p className='font-extrabold uppercase text-appColor'>Patient's Phone number</p>
                        <input
                            type="text"
                            name="patientRef"
                            value={formData.patientPhone}
                            onChange={handleInputChange}
                            className='w-full p-2 rounded-md'
                        />
                    </label>
                    <label className='w-full flex flex-col gap-2'>
                        <p className='font-extrabold uppercase text-appColor'>sex</p>
                        <select
                            className='w-full p-2 rounded-md'
                            name="sex"
                            value={formData.sex}
                            onChange={handleInputChange}
                        >
                            <option value="">Select Sex</option>
                            <option value="MALE">MALE</option>
                            <option value="FEMALE">FEMALE</option>
                        </select>
                    </label>
                    <label className='w-full flex flex-col gap-2'>
                        <p className='font-extrabold uppercase text-appColor'>age</p>
                        <input
                            type="text"
                            name="age"
                            value={formData.age}
                            onChange={handleInputChange}
                            className='w-full p-2 rounded-md'
                        />
                    </label>
                    <label className='w-full flex flex-col gap-2'>
                        <p className='font-extrabold uppercase text-appColor'>date</p>
                        <input
                            type="date"
                            name="date"
                            value={formData.date}
                            onChange={handleInputChange}
                            className='w-full p-2 rounded-md'
                        />
                    </label>
                </section>
                <section className='w-full flex flex-col items-center'>
                    <div className='flex w-full gap-4'>
                        {/* Haematology Section */}
                        <div className="w-full overflow-x-auto">
                            <h3 className='text-xl font-extrabold text-appColor text-center mb-3'>HAEMATOLOGY</h3>
                            <table className="min-w-full bg-white border border-slate-300">
                                <thead>
                                    <tr className="bg-slate-100 text-appColor font-bold uppercase text-center">
                                        <th className="py-2 px-4 border-b border-slate-300">Test</th>
                                        <th className="py-2 px-4 border-b border-slate-300">Result</th>
                                        <th className="py-2 px-4 border-b border-slate-300">Normal Range</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {haem.map((item, index) => (
                                        <tr key={index} className="text-appColor uppercase">
                                            <td className="py-2 px-4">{item.test}</td>
                                            <td className="py-2 px-2">
                                                <input
                                                    type="text"
                                                    onChange={(e) => handleInputChange(e, 'haem', index)}
                                                    value={formData.haemResults[index] || ''}
                                                    className='border border-appColor rounded-md p-1'
                                                />
                                            </td>
                                            <td className="py-2 px-4 text-end text-sm whitespace-pre-line">{item.range}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>

                        {/* Chemistry Section */}
                        <div className="w-full overflow-x-auto">
                            <h3 className='text-xl font-extrabold text-appColor text-center mb-3'>CHEMISTRY</h3>
                            <table className="min-w-full bg-white border border-slate-300">
                                <thead>
                                    <tr className="bg-slate-100 text-appColor font-bold uppercase text-center">
                                        <th className="py-2 px-4 border-b border-slate-300">Test</th>
                                        <th className="py-2 px-4 border-b border-slate-300">Result</th>
                                        <th className="py-2 px-4 border-b border-slate-300">Normal Range</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {chem.map((item, index) => (
                                        <tr key={index} className="text-appColor uppercase">
                                            <td className="py-2 px-4">{item.test}</td>
                                            <td className="py-2 px-4">
                                                <input
                                                    type="text"
                                                    onChange={(e) => handleInputChange(e, 'chem', index)}
                                                    value={formData.chemResults[index] || ''}
                                                    className='border border-appColor rounded-md p-1'
                                                />
                                            </td>
                                            <td className="py-2 px-4 text-end whitespace-pre-line">{item.range}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </section>
                <section>
                    <h2 className='text-xl font-extrabold text-appColor mb-3'>Culture + Sensitivity (S) Resistance</h2>
                    <div className='w-full flex gap-4'>
                        <div className='w-full'>
                            <table className="min-w-full bg-white border border-slate-300">
                                <thead>
                                    <tr className="bg-slate-100">
                                        <th className="py-2 px-4 text-left border-b border-slate-300"></th>
                                        <th className="py-2 px-4 text-left border-b border-slate-300">S</th>
                                        <th className="py-2 px-4 text-left border-b border-slate-300">C</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sens.map((item, i) => (
                                        <tr key={i} className="border-b border-appColor text-appColor uppercase">
                                            <td className="py-2 px-4">{item}</td>
                                            <td className="py-2 px-4">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleInputChange(e, 'sens', `${i}_S`)}
                                                    checked={formData.sensResults[`${i}_S`] || false}
                                                />
                                            </td>
                                            <td className="py-2 px-4">
                                                <input
                                                    type="checkbox"
                                                    onChange={(e) => handleInputChange(e, 'sens', `${i}_C`)}
                                                    checked={formData.sensResults[`${i}_C`] || false}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='w-full bg-green-700 p-5'></div>
                    </div>
                </section>
            </main>
            <button onClick={handleSubmit} className='mt-5 p-2 bg-blue-500 text-white rounded'>Submit</button>
        </div>
    );
}

export default Investigation;
