import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { baseUrl } from '../../../utils/constant';
import axios from 'axios';
import Alert from "../../../components/Alert";

const Consultation = () => {
    const { data } = useSelector((state) => state.user);
    const staff = data.firstname + " " + data.lastname;

    const [formData, setFormData] = useState({
        ref: "",
        type: "CONSULTATION",
        complain: "",
        hoc: "",
        course: "",
        cause: "",
        care: "",
        complication: "",
        pmh: "",
        sr: "",
        fsh: "",
        pd: "",
        staff: staff,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSumbit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post(`${baseUrl}/record.php`, formData);
            console.log(res.data);
            if (res.status === 200) {
                Alert("success", "RECORD SAVED")
            } else {
                Alert("error", "Failed to save record")
            }
        } catch (error) {
            console.log(error);
            Alert("error", error)

        }
    }

    return (
        <div className='p-5'>
            <div className='flex items-center justify-start mb-10'>
                <div className="w-2/4">
                    <input
                        type="text"
                        name="ref"
                        id="ref"
                        className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                        placeholder="Patients Reference Number"
                        value={formData.ref}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <form action="" className='grid grid-cols-2 gap-10'>
                <textarea name="complain" onChange={handleChange} placeholder='Complain' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="hoc" onChange={handleChange} placeholder='History Of Complain' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="course" onChange={handleChange} placeholder='Course' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="cause" onChange={handleChange} placeholder='Cause' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="care" onChange={handleChange} placeholder='Care' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="complication" onChange={handleChange} placeholder='Complication' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="pmh" onChange={handleChange} placeholder='Past Medical History' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="sr" onChange={handleChange} placeholder='System Review' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="fsh" onChange={handleChange} placeholder='Family and Social History' className='rounded-2xl p-3' rows={6} id=""></textarea>
                <textarea name="pd" onChange={handleChange} placeholder='Possible Diagnoses' className='rounded-2xl p-3' rows={6} id=""></textarea>
            </form>
            <div className='flex items-center justify-end mt-10'>
                <button onClick={handleSumbit} className='bg-appColor text-white font-bold px-3 p-2 rounded-lg capitalize w-max '>Save Report</button>
            </div>
        </div >
    )
}

export default Consultation;

