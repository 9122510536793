import React from 'react'
import AddUnits from './AddUnits'

const Units = () => {
  return (
    <div className='h-full w-full flex'>
        <div className='h-full w-3/4 border-r-2 border-slate-400'></div>
        <div className='h-full w-1/4 flex items-center justify-center'>
            <AddUnits />
        </div>
    </div>
  )
}

export default Units