import React, { useState, useEffect } from 'react';
import { LuClipboardSignature } from "react-icons/lu";
import axios from 'axios';
import { baseUrl } from '../../../../utils/constant';
import Alert from '../../../../components/Alert';

const ViewMedical = ({ closeEditModal, id }) => {
  const [formData, setFormData] = useState({
    type: '',
    staff: '',
    complain: '',
    hoc: '',
    course: '',
    cause: '',
    care: '',
    complication: '',
    pmh: '',
    sr: '',
    fsh: '',
    pd: '',
    remark: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      setLoading(true);
      setError(null);

      try {
        console.log(id);
        
        const response = await axios.get(`${baseUrl}/record.php?id=${id}`);
        if (response.status === 200) {
          const data = response.data.data;
          setFormData(data);
          console.log(response.data);

        } else {
          Alert("error", "Failed to fetch record");
          setError('Failed to fetch record');
        }
      } catch (error) {
        Alert("error", "An error occurred while fetching the record");
        setError('An error occurred while fetching the record');
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <div className='p-5'>
      <div className="bg-white px-4">
        <div className='flex gap-5'>
          <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
            <LuClipboardSignature className='text-green-500 text-sm' />
          </div>
          <h3 className="text-lg mt-2 leading-6 font-medium text-gray-900 uppercase" id="modal-title">Patient Record</h3>
        </div>
        <form className="flex flex-col gap-4 mt-3">
          <div className="grid grid-cols-2 gap-4 mt-3">
            <div className="w-full">
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">Type</label>
              <input
                type="text"
                name="type"
                id="type"
                className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                value={formData?.type}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <label htmlFor="staff" className="block text-sm font-medium text-gray-700">Staff</label>
              <input
                type="text"
                name="staff"
                id="staff"
                className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                value={formData?.staff}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className='grid grid-cols-3 gap-4'>
            {
              formData?.complain && formData?.complain !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="complain" className="text-sm font-medium text-gray-700">Complain</label>
                  <textarea
                    name="complain"
                    id="complain"
                    className='border-2 rounded-md p-2'
                    value={formData.complain}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.hoc && formData?.hoc !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="hoc" className="text-sm font-medium text-gray-700">History of Complain</label>
                  <textarea
                    name="hoc"
                    id="hoc"
                    className='border-2 rounded-md p-2'
                    value={formData.hoc}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.course && formData?.course !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="course" className="text-sm font-medium text-gray-700">Course</label>
                  <textarea
                    name="course"
                    id="course"
                    className='border-2 rounded-md p-2'
                    value={formData.course}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.cause && formData?.cause !== "" ? (
                <div className="w-full flex flex-col ">
                  <label htmlFor="cause" className="text-sm font-medium text-gray-700">Cause</label>
                  <textarea
                    name="cause"
                    id="cause"
                    className='border-2 rounded-md p-2'
                    value={formData.cause}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.care && formData?.care !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="care" className="text-sm font-medium text-gray-700">Care</label>
                  <textarea
                    name="care"
                    id="care"
                    className='border-2 rounded-md p-2'
                    value={formData.care}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.complication && formData?.complication !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="complication" className="text-sm font-medium text-gray-700">Complication</label>
                  <textarea
                    name="complication"
                    id="complication"
                    className='border-2 rounded-md p-2'
                    value={formData.complication}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.pmh && formData?.pmh !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="pmh" className="text-sm font-medium text-gray-700">Past Medical History</label>
                  <textarea
                    name="pmh"
                    id="pmh"
                    className='border-2 rounded-md p-2'
                    value={formData.pmh}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.sr && formData?.sr !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="sr" className="text-sm font-medium text-gray-700">System Review</label>
                  <textarea
                    name="sr"
                    id="sr"
                    className='border-2 rounded-md p-2'
                    value={formData.sr}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.fsh && formData?.fsh !== "" ? (
                <div className="w-full">
                  <label htmlFor="fsh" className="block text-sm font-medium text-gray-700">Family and Social History</label>
                  <textarea
                    name="fsh"
                    id="fsh"
                    className='border-2 rounded-md p-2'
                    value={formData.fsh}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.pd && formData?.pd !== "" ? (
                <div className="w-full flex flex-col">
                  <label
                    htmlFor="pd"
                    className=" text-sm font-medium text-gray-700"
                  >
                    Possible Diagnoses
                  </label>
                  <textarea
                    id="pd"
                    name="pd"
                    className="border-2 rounded-md p-2"
                    value={formData.pd}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }
            {
              formData?.remark && formData?.remark !== "" ? (
                <div className="w-full flex flex-col">
                  <label htmlFor="remark" className=" text-sm font-medium text-gray-700">Remark</label>
                  <textarea
                    name="remark"
                    id="remark"
                    className='border-2 rounded-md p-2'
                    value={formData.remark}
                    onChange={handleChange}
                  />
                </div>
              ) : null
            }

          </div>
        </form>
      </div>
      <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
        <button
          onClick={closeEditModal}
          type="button"
          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 border-green-300 text-base font-medium text-green-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
          {loading ? 'Saving...' : 'Close'}
        </button>
      </div>
      {error && <div className="text-red-500">{error}</div>}
    </div>
  )
}

export default ViewMedical;
