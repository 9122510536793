import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import AddEvent from './AddEvent';
import DeleteEvent from './DeleteEvent';
import './MyCalendar.css';

import { useDispatch, useSelector } from "react-redux";
import { setEvents } from '../../redux/Features/Dashboard';
import axios from 'axios';
import { baseUrl } from '../../utils/constant';

const MyCalendar = () => {
  const dispatch = useDispatch();
  const { events } = useSelector((state) => state.dashboard);
  const { data } = useSelector((state) => state.user);

  console.log(data);

  const [allEvents, setAllEvents] = useState([]);
  const [newEvent, setNewEvent] = useState({
    title: '',
    start: '',
    end: '',
    allDay: false,
    id: 0,
  });
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  useEffect(() => {
    let draggableEl = document.getElementById("draggable-el");
    if (draggableEl) {
      new Draggable(draggableEl, {
        itemSelector: ".fc-event",
        eventData: function (eventEL) {
          let title = eventEL.getAttribute("title");
          let id = eventEL.getAttribute("data");
          return { title, id };
        },
      });
    }


    const fetch = async () => {
      try {
        const res = await axios.get(`${baseUrl}/staffs.php`);
        dispatch(setEvents(res.data.data));
        console.log(res.data);
      } catch (error) {
        console.error("Error fetching patients:", error);
      }
    };

    fetch();

  }, []);

  const handleEventClick = (clickInfo) => {
    setDeleteId(clickInfo.event.id);
    setShowDeleteModal(true);
  };

  const handleEventDrop = (dropInfo) => {
    setAllEvents([
      ...allEvents,
      {
        title: dropInfo.event.title,
        start: dropInfo.event.start,
        end: dropInfo.event.end,
        allDay: dropInfo.event.allDay,
        id: dropInfo.event.id,
      },
    ]);
  };

  const handleEventAdd = () => {
    setAllEvents([...allEvents, { ...newEvent, id: Date.now() }]);
    setNewEvent({ title: '', start: '', end: '', allDay: false, id: 0 });
    setShowAddModal(false);
  };

  const handleEventDelete = () => {
    setAllEvents(allEvents.filter(event => event.id !== deleteId));
    setShowDeleteModal(false);
  };

  const openModal = (modalName, id) => {
    switch (modalName) {
      case 'add':
        setShowAddModal(true);
        break;
      case 'delete':
        setDeleteId(id);
        setShowDeleteModal(true);
        break;
      default:
        console.error('Invalid modal name');
    }
  };

  const closeModal = (modalName) => {
    switch (modalName) {
      case 'add':
        setShowAddModal(false);
        break;
      case 'delete':
        setShowDeleteModal(false);
        break;
      default:
        console.error('Invalid modal name');
    }
  };


  const handleDelete = () => {
    setAllEvents(allEvents.filter(event => event.id !== deleteId));
    setShowDeleteModal(false);
  };

  return (
    <main className='w-full h-full flex items-center justify-between'>
      <div className={`h-full ${data.role === "ADMIN" || "ICT" ? "w-4/5" : "w-full"}`}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,dayGridWeek,dayGridDay',
          }}
          height="85vh"
          events={allEvents}
          nowIndicator
          editable
          droppable
          selectable
          selectMirror
          eventClick={handleEventClick}
          eventDrop={handleEventDrop}
        />
      </div>
      {
        data && data.role === "ADMIN" || "ICT" ? (
          <>
            <div id='draggable-el' className='h-full w-1/5 p-5 flex flex-col gap-1 justify-start items-center'>
              {events.map((item, index) => (
                <div
                  key={index}
                  title={item.firstname + " " + item.lastname}
                  data={item.id}
                  className='fc-event bg-appColor text-white w-full flex justify-center items-center p-2 rounded-lg shadow-md font-bold text-sm capitalize'
                >
                  <p>{item.firstname + " " + item.lastname}</p>
                </div>
              ))}
              <button
                className="mt-5 bg-green-500 text-white px-4 py-2 rounded"
                onClick={() => openModal('add')}
              >
                Add Event
              </button>
            </div>
          </>
        ) : (<></>)
      }

      {showAddModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <AddEvent closeAddModal={() => closeModal("add")} />
            </div>
          </div>
        </div>
      )}

      {showDeleteModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
            </div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <DeleteEvent handleDelete={handleDelete} closeDeleteModal={() => closeModal("delete")} />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default MyCalendar;
