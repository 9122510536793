import React from 'react';
import Header from '../../components/Header';
import { useSelector } from 'react-redux';
import { Link, Outlet } from 'react-router-dom';

const Profile = () => {
    const { data } = useSelector((state) => state.user);

    const subLinks = [
        { name: "profile", to: "/profile", icon: "" },
        { name: "vital signs", to: "", icon: "" },
        { name: "payment history", to: "", icon: "" },
        { name: "appointment history", to: "", icon: "" },
    ]

    return (
        <div className=' h-[100vh] md:h-[91vh] overflow-hidden'>
            <Header />
            <main className='bg-slate-100 h-full p-5 md:p-10 flex flex-col gap-10'>
                <div className='w-full h-full'>

                    <div className='flex items-center gap-6'>
                        <div className='h-24 w-24 rounded-3xl bg-slate-500 flex items-center justify-center'>
                            {
                                data && data.avatar ? (
                                    <></>
                                ) : (
                                    <p className='text-white font-extrabold uppercase text-4xl'>{data?.firstname[0] + "" + data?.lastname[0]}</p>
                                )
                            }
                        </div>
                        <div className='flex flex-col gap-2'>
                            <h2 className='font-extrabold text-3xl capitalize text-slate-500'>{data?.firstname + " " + data?.lastname}</h2>
                            <p className='font-bold uppercase text-slate-500'>joined: <span>{data?.created_at}</span></p>
                        </div>
                    </div>
                    <div className='border-b border-slate-400 flex items-center justify-around p-2'>
                        {
                            subLinks.map((link, i) => (
                                <Link key={i} to={link.to} className='w-full capitalize text-sm text-slate-400 font-extrabold'>
                                    {link.name}
                                </Link>
                            ))
                        }
                    </div>
                    <div className='h-full p-4 md:p-10 overflow-scroll '>
                        <Outlet />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Profile