import React from 'react';
import { FaPlus, FaMinus } from "react-icons/fa6";

const ItemCard = ({ data, handleAdd, handleMinus }) => {
    return (
        <div className="bg-white shadow-lg p-5 rounded-xl flex flex-col items-center justify-between gap-4 transition-all duration-300 transform hover:scale-105 hover:shadow-2xl">
            <div className="text-center uppercase">
                <h2 className="font-bold text-appColor text-pretty tracking-tight">{data.product}</h2>
                <p className="font-light text-sm text-gray-500">{data.category}</p>
            </div>
            <div className="flex gap-4 items-center">
                <button
                    onClick={handleAdd}
                    className="border-2 border-appColor p-2 rounded-full text-appColor text-lg hover:bg-appColor hover:text-white transition-all duration-300">
                    <FaPlus />
                </button>
                <p className="text-appColor text-pretty font-extrabold">{data.quantity}</p>
                <button
                    onClick={handleMinus}
                    className="border-2 border-appColor p-2 rounded-full text-appColor text-lg hover:bg-appColor hover:text-white transition-all duration-300">
                    <FaMinus />
                </button>
            </div>
        </div>

    )
}

export default ItemCard