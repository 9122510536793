export const haem = [
    { test: "heamoglobin (hb)", range: "g/dl 12.5-18.0 m \n 11.5-14.5 f" },
    { test: "pcv (hct)", range: "% 40-55 m \n 35-40 f" },
    { test: "white cell count", range: "cmm 4,000-11,000" },
    { test: "red cell count", range: "million/cumm 4.5-6.5 m \n 4.0-5.5 f" },
    { test: "esr (westergren)", range: "mm/hr 0-5 m \n 0-7 f" },
    { test: "reticulocyte count", range: "% 0.2-2" },
    { test: "platelet count", range: "cmm 150-400,000" },
    { test: "total eosinophil count", range: "cmm 40-440" },
    { test: "sicking", range: "" },
    { test: "genotype", range: "" },
    { test: "blood group / ph", range: "" },
    { test: "bleeding time", range: "min 0-11" },
    { test: "clotting time", range: "min 5-11" },
    { test: "prothrombine time", range: "secs" },
    { test: "control", range: "secs 0-14" },
    { test: "pttk", range: "" },
    { test: "direct coonm's", range: "" },
    { test: "indirect coonm's", range: "" },
    { test: "differential (wcc)", range: "" },
    { test: "neutrophils", range: "% 40-75" },
    { test: "lypmphocytes", range: "% 20-46" },
    { test: "eosinophils", range: "% 1-6" },
    { test: "monocytes", range: "% 2-10" },
    { test: "basophils", range: "% 0-1" },
    { test: "mcv", range: "umz 76-93" },
    { test: "mch", range: "mmg 27-32" },
    { test: "mchc", range: "% 30.8-35.0" },
    { test: "malaria parasites", range: "" },
    { test: "microfilaria", range: "" },
];

export const chem = [
    { test: "fasting blood sugar", range: "" },
    { test: "random blood sugar", range: "" },
    { test: "blood urea", range: "" },
    { test: "sodium", range: "" },
    { test: "potassium", range: "" },
    { test: "bicarbonate (hco3)", range: "" },
    { test: "chloride", range: "" },
    { test: "creatnine (blood)", range: "" },
    { test: "creatnine (urine)", range: "" },
    { test: "sgot", range: "" },
    { test: "sgpt", range: "" },
    { test: "alkaline (phos)", range: "" },
    { test: "total bilirubin", range: "" },
    { test: "direct bilirubin", range: "" },
    { test: "total protein", range: "" },
    { test: "albumin", range: "" },
    { test: "cholesterol", range: "" },
    { test: "triglyceride", range: "" },
    { test: "hdl", range: "" },
    { test: "ldl", range: "" },
    { test: "uric acid", range: "" },
    { test: "calcium", range: "" },
]

export const sens = [
    "amplicilllin",
    "amoxicillin",
    "cotrimoxazole",
    "ciprofloxacin",
    "erythromicin",
    "chloramphenicol",
    "gentamycin",
    "nitrofurantion",
    "augmentin",
    "ofloxxacin",
    "norfloxacin",
    "streptomycin",
    "ceftriaxone",
    "levofloxacin",
    "cophalexin",
    "rocephine",
    "salbacef",
    "lyntriaxone",
]
