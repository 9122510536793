import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import axios from 'axios';
import { baseUrl } from '../../utils/constant';
import { useSelector } from 'react-redux';
import Alert from '../../components/Alert';

const ResetPassword = () => {
  const navigate = useNavigate();
  
  const {data} = useSelector((state)=>state.user);


  const [formData, setFormData] = useState({
    password: '',
    confirm_password: '',
  });
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleClick = async (e) => {
    e.preventDefault();
    console.log(formData);
    if (formData.password !== formData.confirm_password) {
      setError("Passwords do not match");
      return;
    } else {
      console.log({...data, password: formData.password });
      try {
        const res = await axios.put(`${baseUrl}/auth.php?action=password`, {...data, password: formData.password, id: data.id });
        console.log(res.data);
        if(res.status === 200){
          Alert("success", "Password Updated");
          navigate("/auth/login");
        }else{
          Alert(res.data.status, res.data.message);
          navigate("/auth/login");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }


  return (
    <main className="w-screen md:h-screen flex items-center justify-center">
      <div className="hidden md:inline w-full h-full bg-[#8F1E63] opacity-10">
      </div>
      <div className="md:w-2/4 h-full flex items-center justify-center p-10">
        <form className="w-full">
          <div className="flex items-center gap-2">
            <Link to="/auth/otp" className="p-2 text-[#8F1E63] bg-[#8F1E63] bg-opacity-10 rounded-md "><IoArrowBack /></Link>
            <p className="text-[#8F1E63] font-semibold">Go back</p>
          </div>
          <div className="w-full flex flex-col gap-10 py-10">
            <div>
              <h2 className="font-bold capitalize text-3xl ">Reset password</h2>
              <p className="font-semibold capitalize mt-2 ">Enter new password to proceed. </p>
            </div>

            <div className="">
              <div className='flex  gap-3'>
                <div className="w-full">
                  <input
                    type="password"
                    name="password"
                    id="password"
                    className="border-2 w-full border-[#8F1E63] text-[#8F1E63] placeholder:text-[#8F1E63] p-2 rounded-lg"
                    placeholder="Enter new password"
                    onChange={handleChange}
                  />
                </div>
                <div className="w-full">
                  <input
                    type="password"
                    name="confirm_password"
                    id="confirm_password"
                    className="border-2 w-full border-[#8F1E63] text-[#8F1E63] placeholder:text-[#8F1E63] p-2 rounded-lg"
                    placeholder="confirm new password"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {
                error && <p className="text-red-500 mt-2">{error}</p>
              }
            </div>

            <button onClick={handleClick} className="flex justify-center items-center gap-1 font-bold capitalize bg-[#8F1E63] text-white w-full p-2 rounded-md"> Reset Password </button>
          </div>
        </form>
      </div>
    </main>
  )
}

export default ResetPassword