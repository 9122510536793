import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import axios from 'axios';
import { baseUrl } from '../../utils/constant';
import Alert from '../../components/Alert';
import { useDispatch } from 'react-redux';
import { setData, setID } from '../../redux/Features/User';

const AuthOtp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [token, setToken] = useState(new Array(6).fill(''));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return;

        setToken([...token.map((d, idx) => (idx === index ? element.value : d))]);

        // Focus next input
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    const handleClick = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.get(`${baseUrl}/auth.php?token=${token.join('')}`);
            console.log(response.data);

            if (response.status === 200) {
                Alert('success', "Successful");
                dispatch(setData(response.data));
                navigate("/auth/resetpassword");
            } else {
                Alert('error', response.data.message);
            }

        } catch (error) {
            console.log(error);
            Alert('error', error );
        }
    };

    return (
        <main className="w-full md:h-screen flex items-center justify-center">
            <div className="hidden md:inline w-full h-full bg-[#8F1E63] opacity-10">
            </div>
            <div className="md:w-2/4 h-full flex items-center justify-center p-10">
                <form className="w-full">
                    <div className="flex items-center gap-2">
                        <Link to="/auth/forgotpassword" className="p-2 text-[#8F1E63] bg-[#8F1E63] bg-opacity-10 rounded-md"><IoArrowBack /></Link>
                        <p className="text-[#8F1E63] font-semibold">Go back</p>
                    </div>
                    <div className="w-full flex flex-col gap-10 py-10">
                        <div>
                            <h2 className="font-bold capitalize text-3xl">Reset password</h2>
                            <p className="font-semibold capitalize mt-2">Enter OTP sent to your Email to proceed.</p>
                        </div>

                        <div className="flex justify-center items-center gap-3">
                            {token.map((data, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    className="border-2 w-12 text-center h-12 border-[#8F1E63] text-[#8F1E63] p-2 rounded-lg focus:outline-none"
                                    maxLength="1"
                                    value={data}
                                    onChange={e => handleChange(e.target, index)}
                                    onFocus={e => e.target.select()}
                                />
                            ))}
                        </div>

                        <button type="button" onClick={handleClick} className="flex justify-center items-center gap-1 font-bold capitalize bg-[#8F1E63] text-white w-full p-2 rounded-md">Reset Password</button>
                        <p className="font-semibold">Didn't get the code? <button className="text-[#8F1E63] font-bold">Resend</button></p>
                    </div>
                </form>
            </div>
        </main>
    );
}

export default AuthOtp;
