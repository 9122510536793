import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    patient: {
        id: "",
        firstname: "",
        lastname: "",
        ref: "",
        end: "",
        type: "",
    },
    isLoading: false,
    isError: false,
    isSuccess: false,
    errorMessage: "",
    patientDetails: [],
};

export const patientSlice = createSlice({
    name: "patient",
    initialState,
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isLoading = false;
            state.errorMessage = "";
            state.successMessage = "";
            return state;
        },
        setError: (state, { payload }) => {
            state.isError = true;
            state.errorMessage = payload;
        },
        clearError: (state) => {
            state.isError = false;
            state.errorMessage = "";
        },
        setID: (state, { payload }) => {
            state.patient.id = payload;
        },
        setRef: (state, { payload }) => {
            state.patient.ref = payload;
        },
        setFirstname: (state, { payload }) => {
            state.patient.firstname = payload;
        },
        setLastname: (state, { payload }) => {
            state.patient.lastname = payload;
        },
        setType: (state, { payload }) => {
            state.patient.type = payload;
        },
        setPatientDetails: (state, { payload }) => {
            state.patientDetails = payload;
        },
    },
});

export const {
    clearState,
    setError,
    clearError,
    setID,
    setRef,
    setFirstname,
    setLastname,
    setPatientDetails,
    setType,
} = patientSlice.actions;

export default patientSlice.reducer;
