import React from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';

const ConsultationLayout = () => {
    const location = useLocation();

    return (
        <div>
            <div className='flex gap-3'>
                <Link 
                    to="/dashboard/consultation" 
                    className={`font-bold p-2 ${location.pathname === "/dashboard/consultation" ? "text-appColor border-b-2" : ""}`}
                >
                    Consultation
                </Link>
                <Link 
                    to="/dashboard/consultation/prescription" 
                    className={`font-bold p-2 ${location.pathname === "/dashboard/consultation/prescription" ? "text-appColor border-b-2" : ""}`}
                >
                    Prescription
                </Link>
            </div>
            <main>
                <Outlet />
            </main>
        </div>
    );
}

export default ConsultationLayout;
