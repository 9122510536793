import React, { useEffect, useState } from 'react';
import { MdRemoveRedEye } from "react-icons/md";
import Table from '../../../components/Table';
import { baseUrl } from '../../../utils/constant';
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import { setPrescriptions } from '../../../redux/Features/Dashboard';
import PrescriptionDetails from './PrescriptionDetails';

const Prescriptions = () => {
    const dispatch = useDispatch();
    const { prescription } = useSelector((state) => state.dashboard);

    const [isOpenViewModal, setIsOpenViewModal] = useState(false);
    const [selectedId, setSelectedId] = useState(null);

    useEffect(() => {
        const fetchPatients = async () => {
            try {
                const res = await axios.get(`${baseUrl}/prescription.php`);
                console.log(res.data?.data);
                dispatch(setPrescriptions(res.data?.data));
                
            } catch (error) {
                console.error("Error fetching prescriptions:", error);
            } 
        };

        fetchPatients();
    }, [dispatch]);

    const openViewModal = (id) => {
        setSelectedId(id);
        setIsOpenViewModal(true);
    };

    const closeViewModal = () => {
        setIsOpenViewModal(false);
    };

    const columns = [
        { name: 'ID', selector: row => row.id, sortable: true },
        { name: 'REF', selector: row => row.ref, sortable: true },
        { name: 'DOCTOR', selector: row => row.doctor, sortable: true },
        { name: 'PRESCRIPTION', selector: row => row.prescription, sortable: true },
        { name: 'CREATED', selector: row => row.created_at, sortable: true },
        {
            name: 'ACTIONS',
            cell: row => (
                <>
                    <button
                        onClick={() => openViewModal(row.id)}
                        className="bg-appColor flex items-center gap-2 text-white font-bold text-sm rounded-md px-3 py-1 focus:outline-none"
                    >
                        <MdRemoveRedEye className='text-white' />
                    </button>
                </>
            ),
        },
    ];

    return (
        <main className='w-full h-full bg-white rounded-lg shadow-md p-4'>
            <Table
                title="Prescriptions"
                label={columns}
                filter={"doctor"}
                data={prescription} // Now passing the correct array
            />
            {isOpenViewModal && (
                <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
                    <div className="flex justify-center items-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                            <div className="absolute inset-0 bg-slate-600 opacity-75"></div>
                        </div>
                        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                            <PrescriptionDetails closeViewModal={closeViewModal} id={selectedId} />
                        </div>
                    </div>
                </div>
            )}
        </main>
    );
};

export default Prescriptions;
