import React, { useState } from 'react';
import axios from 'axios';
import { MdOutlineAdd } from 'react-icons/md';
import { baseUrl } from '../../../../utils/constant';
import Alert from '../../../../components/Alert';

const AddUnits = () => {
    const [formData, setFormData] = useState({
        category: '',
    });

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleClear = () => {
        // Clear the formData state
        setFormData({
            category: '',
        });
    }

    const save = async () => {
        setLoading(true);
        try {
            console.log(formData);
            // Save the category
            const res = await axios.post(`${baseUrl}/drug_category.php`, formData);
            console.log(res);
            if (res.status === 201) {
                Alert(res.data.status, res.data.message);
            } else {
                Alert(res.data.status, res.data.message);
            }
        } catch (error) {
            Alert('error', 'An error occurred while saving the category');
            console.error('An error occurred while saving the category', error.message);
        }
        finally{
            setLoading(true);
        }
    };

    return (
        <div>
            <div className="bg-white p-5">
                <div className="flex flex-col">
                    <div className='flex gap-4'>
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-10 sm:w-10">
                            <MdOutlineAdd className='text-green-500 text-base' />
                        </div>
                        <h3 className="text-lg mt-2 leading-6 font-bold uppercase text-slate-800" id="modal-title">Create Unit</h3>
                    </div>
                    <div className="w-full mt-3 text-center sm:text-left">
                        <form className="flex flex-col gap-4 mt-3 w-full">
                            <div className="w-full">
                                <input
                                    type="text"
                                    name="unit"
                                    id="unit"
                                    value={formData.category}  
                                    className="border-2 p-2 block w-full sm:text-sm border-slate-300 rounded-md"
                                    placeholder="Unit"
                                    onChange={handleChange}
                                />
                            </div>
                        </form>

                        <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                            <button
                                type="button"
                                onClick={save}
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 border-green-300 text-base font-medium text-green-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                            >
                                Save
                            </button>
                            <button
                                type="button"
                                onClick={handleClear}  
                                className="mt-3 w-full inline-flex justify-center rounded-md border shadow-sm px-4 py-2 bg-white text-base font-medium focus:outline-none sm:mt-0 sm:w-auto sm:text-sm"
                            >
                                Clear
                            </button>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddUnits;
